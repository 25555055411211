import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration1 from '../components/Decoration/Decoration-1';
import VideoYoutube from '../components/VideoYoutube';

// import imageFirstSection from '../assets/images/photos/shift/image-main.png';
import imageVideoPreview from '../assets/images/photos/shift/video-preview.png';
import iconCheckmark from '../assets/images/vectors/icons/icon-checkmark-circle--small.svg';

import '../assets/styles/pages/shift-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const ShiftPage = ({ data }) => {
  const prismicData = data.prismic.allShift_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="SHIFT" />
      <div className="shift-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({
  data: { imageFirstSection },
  prismicData: { first_section_title, first_section_subtitle },
}) => (
  <section className="common-first-section">
    <div className="container">
      <div className="cfs__wrapper">
        <div className="cfs__l">
          <div>
            <h1 className="c-h1 cfs__l__title">{RichText.asText(first_section_title)}</h1>
            <p>{RichText.asText(first_section_subtitle)}</p>
            <StartJourneyButton text={'Start your journey'} />
          </div>
        </div>
        <div className="cfs__r">
          <Decoration1 />
          <Img fluid={imageFirstSection.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  </section>
);

const SecondSection = ({ prismicData: { second_section_list, video_link, video_preview } }) => (
  <section className="c-section-my second-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6  col-xl-7">
          <div className="second-section__left h-100">
            <VideoYoutube url={video_link.url} imagePreview={video_preview.url} />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4 offset-xl-1 second-section__right">
          <ul>
            {second_section_list.map((item, index) => (
              <li key={index}>
                <img src={iconCheckmark} alt="" />
                <span>{RichText.asText(item.text)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query {
    prismic {
      allShift_pages {
        edges {
          node {
            first_section_title
            first_section_subtitle
            second_section_list {
              text
            }
            video_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            video_preview
          }
        }
      }
    }
    imageFirstSection: file(relativePath: { eq: "photos/shift/image-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 816, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default ShiftPage;
